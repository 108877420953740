import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import loadScriptBookSession from 'utils/loadScriptBookSession';
// import ModalSendEmail from 'components/ModalSendEmail/ModalSendEmail';
import NewsletterForm from 'components/NewsletterForm/NewsletterForm';
import { BUCKET_URL } from 'constants';

// import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/externalLink.svg';
import { ReactComponent as NavOpen } from 'assets/icons/navOpen.svg';
import { ReactComponent as NavClose } from 'assets/icons/navClose.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/medium.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/youtube.svg';
import { ReactComponent as AmazonIcon } from 'assets/icons/amazon.svg';

import classes from './Homepage.module.scss';

const rates = [
    { name: '*Low bono', value: 75 },
    { name: 'Stretching', value: 225 },
    { name: 'Sustainable', value: 300 },
    { name: 'Comfortable', value: 375 },
    { name: 'Thriving', value: 450 }
];

const Homepage = () => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNavOpen, setNavIsOpen] = useState(false);
    const [rateIndex, setRateIndex] = useState(2); // Default to 'sustainable'
    const coachingRef = useRef(null);
    const bookRef = useRef(null);
    // const eventsRef = useRef(null);
    const aboutRef = useRef(null);
    // const storiesRef = useRef(null);
    const packagesRef = useRef(null);
    const sessionRef = useRef(null);

    // const handleOpenModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    // };

    const toggleNavMenu = () => {
        setNavIsOpen(!isNavOpen);
    };

    const handleSliderChange = event => {
        setRateIndex(Number(event.target.value));
    };

    const executeScroll = useCallback(
        ref => () => {
            if (ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        },
        []
    );

    // const getACELink = () => (
    //     <a
    //         id="main-link-academy"
    //         href="https://www.acecoachtraining.com/"
    //         target="_blank"
    //         rel="noreferrer"
    //         className={classes.contractLink2}
    //     >
    //         Academy for Coaching Excellence <ExternalLink />
    //     </a>
    // );

    const getVoyageLAInterviewLink = () => (
        <a
            id="main-link-voyagela-interview"
            href="https://voyagela.com/interview/exploring-life-business-with-stas-kurgansky-of-stas-kurgansky-coaching-llc/"
            target="_blank"
            rel="noreferrer"
            className={classes.contractLink4}
        >
            <i>Exploring Life & Business with Stas Kurgansky</i> <ExternalLink />
        </a>
    );

    // const getICFLink = () => (
    //     <a
    //         className={classes.contractLink3}
    //         href="https://www.coachingfederation.org/"
    //         id="main-link-coaching-federation"
    //         target="_blank"
    //         rel="noreferrer"
    //     >
    //         International Coaching Federation <ExternalLink />
    //     </a>
    // );

    // useEffect(() => {
    //     if (isNavOpen || isModalOpen) {
    //         document.body.classList.add('no-scroll');
    //     } else {
    //         document.body.classList.remove('no-scroll');
    //     }

    //     // Clean up function
    //     return () => {
    //         document.body.classList.remove('no-scroll');
    //     };
    // }, [isNavOpen, isModalOpen]);

    useEffect(() => {
        // do not run when in the react-snap mode
        if (!window.navigator.userAgent.includes('ReactSnap')) {
            loadScriptBookSession();
        }
    }, []);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <div className={classes.headerLeft}>
                    <div className={classes.containerLogo}>
                        <a id="header-link-main-logo" href="/" className={classes.logoLink}>
                            <img className={classes.logoImg} src={BUCKET_URL + 'new+logo+160.png'} alt="Tree logo" />
                        </a>
                    </div>
                    <div className={classes.headerTitleContainer}>
                        <div className={classes.headerTitle}>Stas Kurgansky</div>
                        <div className={classes.headerSubtitle}>Professional Life Coach</div>
                    </div>
                </div>
                <div className={classes.headerRight}>
                    <a
                        id="header-nav-link-client-login"
                        href="https://app.paperbell.com/customer/sessions/emails/new"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerNavButton}
                    >
                        Client Login <ExternalLink />
                    </a>
                    <button
                        id="header-nav-button-guide"
                        onClick={executeScroll(bookRef)}
                        className={classes.headerNavButton}
                    >
                        12 Challenges eBook
                    </button>
                    <button
                        id="header-nav-button-about"
                        onClick={executeScroll(aboutRef)}
                        className={classes.headerNavButton}
                    >
                        About Me
                    </button>
                    <button
                        id="header-nav-button-coaching"
                        onClick={executeScroll(coachingRef)}
                        className={classes.headerNavButton}
                    >
                        Coaching
                    </button>
                    {/* <button
                        id="header-nav-button-events"
                        onClick={executeScroll(eventsRef)}
                        className={classes.headerNavButton}
                    >
                        Events
                    </button> */}
                    {/* <button
                        id="header-nav-button-stories"
                        onClick={executeScroll(storiesRef)}
                        className={classes.headerNavButton}
                    >
                        Stories
                    </button> */}
                    <button
                        id="header-nav-button-packages"
                        onClick={executeScroll(packagesRef)}
                        className={classes.headerNavButton}
                    >
                        Packages & Rates
                    </button>
                    <button
                        id="header-nav-button-book-session"
                        onClick={executeScroll(sessionRef)}
                        className={classNames(classes.button, classes.buttonPrimary)}
                    >
                        Create Action Plan
                    </button>
                </div>
                <button
                    id="header-button-hamburger"
                    className={classNames(classes.hamMenu, isNavOpen && classes.hamMenuOpen)}
                    onClick={toggleNavMenu}
                >
                    {isNavOpen ? (
                        <NavClose className={classNames(classes.navIcon, classes.navCloseIcon)} />
                    ) : (
                        <NavOpen className={classNames(classes.navIcon, classes.navOpenIcon)} />
                    )}
                </button>
                <div className={classNames(classes.hamNav, isNavOpen && classes.hamNavActive)}>
                    <nav>
                        <ul>
                            <li>
                                <a
                                    id="header-ham-link-client-login"
                                    href="https://app.paperbell.com/customer/sessions/emails/new"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.hamNavLink}
                                >
                                    Client Login <ExternalLink />
                                </a>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-guide"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(bookRef)}
                                >
                                    12 Challenges eBook
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-about"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(aboutRef)}
                                >
                                    About Me
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-coaching"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(coachingRef)}
                                >
                                    Coaching
                                </button>
                            </li>
                            {/* <li>
                                <button
                                    id="header-ham-button-events"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(eventsRef)}
                                >
                                    Events
                                </button>
                            </li> */}
                            {/* <li>
                                <button
                                    id="header-ham-button-stories"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(storiesRef)}
                                >
                                    Stories
                                </button>
                            </li> */}
                            <li>
                                <button
                                    id="header-ham-button-packages"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(packagesRef)}
                                >
                                    Packages & Rates
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-book-session"
                                    className={classNames(classes.button, classes.buttonPrimary)}
                                    onClick={executeScroll(sessionRef)}
                                >
                                    Create Action Plan
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* <div className={classes.headerSocialContainer}>
                    <a
                        id="header-link-youtube"
                        href="https://www.youtube.com/@StasKurgCoach?sub_confirmation=1"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <YouTubeIcon />
                    </a>
                    <a
                        id="header-link-medium"
                        href="https://medium.staskurg.com/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <MediumIcon />
                    </a>
                    <a
                        id="header-link-instagram"
                        href="https://www.instagram.com/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <InstagramIcon />
                    </a>
                    <a
                        id="header-link-linkedin"
                        href="https://www.linkedin.com/in/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <LinkedInIcon />
                    </a>
                    <a
                        id="header-link-amazon"
                        href="https://www.amazon.com/author/staskurgcoach"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <AmazonIcon />
                    </a>
                </div> */}
            </header>
            <main>
                <div className={classes.contentContainer}>
                    <section>
                        <div className={classes.sectionHero}>
                            <div className={classes.sectionHeroWrapper}>
                                <div className={classes.sectionHeroContainer}>
                                    <div className={classes.sectionHeroTextBlock}>
                                        <h1 className={classes.heroTitle}>
                                            I Support LGBTQ+ Immigrants to Find Belonging and Live Authentically
                                        </h1>
                                        <h2 className={classes.heroSubtitle}>
                                            Stuck Between Multiple Identities and Don't Know Where You Belong? Download
                                            My Free Guide to Learn What is Holding You Back
                                        </h2>
                                    </div>
                                    {/* <div className={classes.singleVideoBlock}>
                                        <iframe
                                            className={classes.singleVideoFrame}
                                            src="https://www.youtube.com/embed/TDxA4mt20eE?si=PviE92rPWC0p6Mcj"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </div> */}
                                    <div className={classes.heroActionBlock}>
                                        {/* <p className={classes.subtitle}>
                                            <a
                                                id="main-hero-link-12-steps"
                                                href="https://guide.staskurg.com"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                Get FREE eBook <i>12 Challenges</i>
                                                <ExternalLink />
                                            </a>
                                        </p> */}
                                        <div className={classNames(classes.bioButtonWrapper)}>
                                            <button
                                                id="main-hero-button-book-session"
                                                onClick={executeScroll(bookRef)}
                                                className={classNames(classes.button, classes.buttonPrimary)}
                                            >
                                                I Want to Learn More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>Reviews</h2>
                            <div className="sk-ww-google-reviews" data-embed-id="25401672"></div>
                            <div className={classes.bodySocialContainer}>
                                <a
                                    id="header-link-youtube"
                                    href="https://www.youtube.com/@StasKurgCoach?sub_confirmation=1"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.bodyIconButton}
                                >
                                    <YouTubeIcon />
                                </a>
                                <a
                                    id="header-link-medium"
                                    href="https://medium.staskurg.com/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.bodyIconButton}
                                >
                                    <MediumIcon />
                                </a>
                                <a
                                    id="header-link-instagram"
                                    href="https://www.instagram.com/staskurgcoach/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.bodyIconButton}
                                >
                                    <InstagramIcon />
                                </a>
                                <a
                                    id="header-link-linkedin"
                                    href="https://www.linkedin.com/in/staskurgcoach/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.bodyIconButton}
                                >
                                    <LinkedInIcon />
                                </a>
                                <a
                                    id="header-link-amazon"
                                    href="https://www.amazon.com/author/staskurgcoach"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.bodyIconButton}
                                >
                                    <AmazonIcon />
                                </a>
                            </div>
                        </div>
                    </section>
                    <section ref={bookRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>12 Challenges eBook</h2>
                            <div className={classes.bookContainer}>
                                <div className={classes.bookTextContainer}>
                                    <p className={classes.paragraph}>
                                        Have you ever felt like you're living between multiple worlds, never fully{' '}
                                        <b>belonging</b> to any of them? Many LGBTQ+ immigrants know this feeling. We
                                        often face numerous challenges: balancing{' '}
                                        <b>cultural and societal expectations</b>, searching for a place where we truly
                                        belong, navigating the complexities of our <b>identity</b>, and trying to fit
                                        in.
                                    </p>
                                    <p className={classes.paragraph}>
                                        These experiences often create deep <b>internal conflicts</b>, fragmenting our
                                        identities and limiting our <b>confidence</b>. In this practical guide, you'll
                                        learn about the 12 key challenges that are holding you back from finding your
                                        place and <b>living your most authentic life</b>.
                                    </p>
                                </div>
                                <div className={classes.bookImageAndDescriptionContainer}>
                                    <div className={classes.bookImageContainer}>
                                        <div className={classes.bookImageBox}>
                                            <a
                                                className={classes.logoLink}
                                                id="main-guide-image-link-book"
                                                href="https://guide.staskurg.com"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    className={classes.bookImage}
                                                    src={BUCKET_URL + 'book_mock_320x436.png'}
                                                    alt="12 Steps Guide book"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className={classes.subtitle}>Your Main Challenges:</h3>
                                        <ul className={classes.bioList}>
                                            <li className={classes.paragraph}>
                                                <b>Feeling Torn Between Identities:</b> You're struggling to reconcile
                                                your LGBTQ+ identity with your cultural roots and societal expectations,
                                                leaving you feeling fragmented and unsure of where you belong.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <b>Struggling to Build Meaningful Connections:</b> You've found it
                                                difficult to form deep, authentic relationships because of self-doubt,
                                                fear, or language barriers that hold you back from truly being yourself.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <b>Navigating Internal Conflicts:</b> You face constant internal
                                                battles, questioning your worth, your decisions, and how to move forward
                                                as your authentic self without compromising any part of who you are.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <b>Suppressing Your True Voice:</b> You've learned to hide parts of
                                                yourself to fit into different settings, leading to feelings of
                                                isolation and frustration at being unable to fully express your
                                                authentic identity.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.bookTextContainer}>
                                    <div>
                                        <h3 className={classes.subtitle}>Addressing the Challenges</h3>
                                        <p className={classes.paragraph}>
                                            This book breaks down 12 of the most impactful challenges LGBTQ+ immigrants
                                            face on their journey to authenticity. You'll gain valuable insights based
                                            on my personal experiences and those of others who have walked similar
                                            paths. Each challenge comes with a <b>clear action plan</b> to help you make
                                            real, lasting changes.
                                        </p>
                                    </div>
                                    <p className={classes.paragraph}>
                                        The greatest thing about these challenges is that, as you work through them,
                                        you'll recognize that what once <b>felt like a burden</b> and the source of your
                                        struggles is actually a <b>source of great richness</b>—filled with culture,
                                        experience, and a unique perspective on the world.
                                    </p>
                                </div>
                                <div className={classes.bookTextContainer}>
                                    <div>
                                        <h3 className={classes.subtitle}>Ready to Explore?</h3>
                                        <p className={classes.paragraph}>
                                            Download this book for free. It's my gift to help you uncover and overcome
                                            the challenges standing in the way of your authentic life. By addressing
                                            these issues, you'll transform from feeling fragmented and out of place to
                                            confidently creating a life that aligns with your true identity and
                                            aspirations.
                                        </p>
                                    </div>
                                </div>
                                <div className={classes.bookActionContainer}>
                                    <p className={classes.paragraph}>
                                        <a
                                            id="main-guide-link-12-steps"
                                            href="https://guide.staskurg.com"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.contractLink}
                                        >
                                            Get FREE eBook <i>12 Challenges</i>
                                            <ExternalLink />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section ref={aboutRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>About Me</h2>
                            <div className={classes.bioWrapper}>
                                <div className={classes.photoContainer}>
                                    <div>
                                        <img
                                            className={classes.photoImg}
                                            src={BUCKET_URL + 'website_photo_professional.jpg'}
                                            alt="Profile"
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.nameAndHeartBox}>
                                            <a
                                                id="main-ebook-link-author-linkedin"
                                                href="https://www.linkedin.com/in/staskurgcoach/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                <p className={classes.subtitle}>Stas Kurgansky</p>
                                            </a>
                                            <div className={classes.heartImgBox}>
                                                <img
                                                    className={classes.heartImg}
                                                    src={BUCKET_URL + 'rainbow-heart.png'}
                                                    alt="LGBTQ+ Colored Heart"
                                                />
                                            </div>
                                        </div>
                                        <p className={classes.paragraph}>Life Coach/Author/Founder</p>
                                    </div>
                                </div>
                                <div className={classes.bioContainer}>
                                    <div>
                                        <p className={classes.paragraph}>
                                            Hello! I'm Stas (he/him), a proud Russian-American immigrant and member of
                                            the LGBTQ+ community. As a professional life coach and author of
                                            <a
                                                id="main-about-link-amazon-book"
                                                href="https://a.co/d/3hEdgRI"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                <i>12 Challenges</i>
                                            </a>{' '}
                                            guide, I specialize in supporting people like you in navigating the{' '}
                                            <b>complexities of your identity</b> and building an <b>authentic life</b>.
                                        </p>
                                        <p className={classes.paragraph}>
                                            Like many LGBTQ+ immigrants, I faced many challenges while balancing
                                            multiple identities—trying to fit my family's traditional values, live
                                            openly as a gay man, and adapt to a new culture in the United States. Even
                                            though I had built a <b>successful life</b> after moving here,{' '}
                                            <b>something still felt missing</b>. I struggled with a{' '}
                                            <b>lack of meaning</b>, fulfillment, and belonging. Deep down, I knew there
                                            was <b>more to life</b>, but I couldn't quite figure out what was holding me
                                            back.
                                        </p>
                                        <p className={classes.paragraph}>
                                            To truly understand myself, I took a year off from my career to focus on
                                            unresolved <b>issues related to my LGBTQ+ identity</b>. During that time, I
                                            confronted challenges I hadn't realized were <b>preventing me</b> from
                                            embracing my true self with <b>confidence and freedom</b>. What I learned
                                            during my transformation laid the foundation for this book. The same steps I
                                            took to overcome those challenges are the ones I now use to{' '}
                                            <b>help my clients</b> transform their lives. If you ever feel like you need
                                            support, don't hesitate to reach out—I'm here for you.
                                        </p>
                                        <p className={classes.paragraph}>
                                            If you're interested in learning more about my transition, feel free to
                                            check out my interview in VoyageLA's online magazine:{' '}
                                            {getVoyageLAInterviewLink()}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className={classes.subtitle}>Identity & Diversity</h3>
                                <p className={classes.paragraph}>
                                    I love partnering with individuals from diverse backgrounds, life stages &
                                    self-identities, including:
                                </p>
                                <ul className={classes.bioList}>
                                    <li className={classes.paragraph}>immigrants, expats and refugees.</li>
                                    <li className={classes.paragraph}>
                                        first-gens and those who grew up in bilingual or biracial family.
                                    </li>
                                    <li className={classes.paragraph}>
                                        LGBTQIA2S+ folks (lesbian, gay, bisexual, transgender, queer and/or questioning,
                                        intersex, asexual, two-spirit, and others).
                                    </li>
                                    <li className={classes.paragraph}>BIPOC (Black, Indigenous, & people of color).</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className={classes.subtitle}>Credentials & Affiliations</h3>
                                <div className={classes.credsItemsContainer}>
                                    <div className={classes.credsItemContainer}>
                                        <a
                                            id="main-about-link-ace"
                                            href="https://acecoachtraining.com/coach-training/coach-training-curriculum/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.contractLink4}
                                        >
                                            <div className={classes.credsLogoContainer}>
                                                <img
                                                    className={classes.credsLogo}
                                                    src={BUCKET_URL + 'logos/ace.png'}
                                                    alt="Academy for Coaching Excellence Website"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className={classes.credsItemContainer}>
                                        <a
                                            id="main-about-link-icf"
                                            href="https://coachingfederation.org/credentials-and-standards/credentials-paths/acc-credential/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.contractLink4}
                                        >
                                            <div className={classes.credsLogoContainer}>
                                                <img
                                                    className={classes.credsLogo}
                                                    src={BUCKET_URL + 'logos/icf.png'}
                                                    alt="Internation Coaching Federation Website"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className={classes.credsItemContainer}>
                                        <a
                                            id="main-about-link-lgbtbe"
                                            href="https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-docs/LGBTBE+certificate.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.contractLink4}
                                        >
                                            <div className={classes.credsLogoContainer}>
                                                <img
                                                    className={classes.credsLogo}
                                                    src={BUCKET_URL + 'logos/nglcc.png'}
                                                    alt="National LGBTQ Chamber of Commerce Certificate"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className={classes.credsItemContainer}>
                                        <a
                                            id="main-about-link-laglcc"
                                            href="https://members.laglcc.org/list/member/stas-kurgansky-coaching-llc-los-angeles-7469"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.contractLink4}
                                        >
                                            <div className={classes.credsLogoContainer}>
                                                <img
                                                    className={classes.credsLogo}
                                                    src={BUCKET_URL + 'logos/laglcc.png'}
                                                    alt="Los Angeles LGBTQ Chamber of Commerce Website"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.callToActionContainer}>
                                <div>
                                    <h3 className={classes.subtitle}>Ready to Start Your Journey?</h3>
                                    <p className={classes.paragraph}>
                                        Schedule a free “Create Action Plan” session to explore how coaching with me can
                                        support your unique journey. Together, we'll identify your challenges and create
                                        a personalized plan to help you move confidently toward the life you've always
                                        envisioned. Take the first step toward living as your authentic self.
                                    </p>
                                </div>
                                <p className={classNames(classes.buttonWrapper)}>
                                    <button
                                        id="main-about-button-book-session"
                                        onClick={executeScroll(sessionRef)}
                                        className={classNames(classes.button, classes.buttonPrimary)}
                                    >
                                        Create Action Plan
                                    </button>
                                </p>
                            </div>
                        </div>
                    </section>
                    <section ref={coachingRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio} ${classes.coachingPadding}`}>
                            <h2 className={classes.title}>Coaching with Me</h2>
                            <div className={classes.bioTwoWrapper}>
                                <div className={classes.bioTwoContainer}>
                                    <div className={classes.guideImageAndDescriptionContainer}>
                                        <div className={classes.guideDescriptionContainer}>
                                            <div>
                                                <p className={classes.paragraph}>
                                                    If you identify as a member of the LGBTQ+ community, have an
                                                    immigrant background, and the{' '}
                                                    <a
                                                        id="main-ebook-link-12-steps"
                                                        href="https://guide.staskurg.com"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={classes.contractLink}
                                                    >
                                                        <i>12 Challenges</i>
                                                    </a>{' '}
                                                    ebook resonates with you, <b>I'm here to support you</b> on your
                                                    journey. My coaching approach is{' '}
                                                    <b>direct, compassionate, and results-focused</b>, with an emphasis
                                                    on achieving breakthroughs in every session. My knowledge comes from{' '}
                                                    <b>firsthand experience, not just theory</b>. Everything I bring to
                                                    coaching has been proven to work for both me and my clients.
                                                </p>
                                            </div>
                                            <div>
                                                <h3 className={classes.subtitle}>
                                                    What to Expect from Working with Me:
                                                </h3>
                                                <ul className={classes.bioList}>
                                                    <li className={classes.paragraph}>
                                                        <b>Align with Your True Self:</b> Follow a personalized
                                                        transformation plan based on the{' '}
                                                        <a
                                                            id="main-ebook-link-12-steps"
                                                            href="https://guide.staskurg.com"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            <i>12 Challenges</i>
                                                        </a>{' '}
                                                        guide to align with who you truly are.
                                                    </li>
                                                    <li className={classes.paragraph}>
                                                        <b>Learn Practical Tools:</b> Gain techniques to overcome
                                                        impostor syndrome and self-doubt, empowering you to achieve
                                                        tangible results in every area of your life.
                                                    </li>
                                                    <li className={classes.paragraph}>
                                                        <b>Stay Motivated:</b> Receive continuous accountability and
                                                        genuine partnership throughout your transformation.
                                                    </li>
                                                    <li className={classes.paragraph}>
                                                        <b>Discover Effective Strategies:</b> Access proven methods to
                                                        simplify your journey and keep you aligned with your goals.
                                                    </li>
                                                    <li className={classes.paragraph}>
                                                        <b>Get Ongoing Support:</b> Receive adjustments to your plan as
                                                        you grow and encounter new challenges, ensuring it evolves with
                                                        you.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={classes.guideImageContainer}>
                                            <div className={classes.guideImageBox}>
                                                <img
                                                    className={classes.guideImage}
                                                    src={BUCKET_URL + 'website_photo_colorful.jpg'}
                                                    alt="My profile"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className={classes.subtitle}>Ready to Get Started?</h3>
                                        <p className={classes.paragraph}>
                                            Book your "Create Action Plan" session today to explore how coaching with me
                                            can support your unique journey. Together, we'll identify your challenges
                                            and create a personalized plan to help you move confidently toward the life
                                            you've always envisioned. Take the first step toward living authentically.
                                        </p>
                                    </div>
                                    <div>
                                        <p className={classNames(classes.buttonWrapper)}>
                                            <button
                                                id="main-about-button-book-session"
                                                onClick={executeScroll(sessionRef)}
                                                className={classNames(classes.button, classes.buttonPrimary)}
                                            >
                                                Create Action Plan
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={classes.benefitsWrapper}>
                                <h3 className={classes.subtitle}>Four Stages of Personal Transformation</h3>
                                <div className={classes.benefitsContainer}>
                                    <ul className={classes.benefitsList}>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'wisdom.webp'}
                                                        alt="Wisdom"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Build a Foundation</h4>
                                                    <p className={classes.paragraph}>
                                                        In this first stage, you'll focus on enhancing self-awareness,
                                                        strengthening boundaries, and creating a solid base for your
                                                        personal growth. It's about healing from past experiences and
                                                        learning to build the life you want. By laying this groundwork,
                                                        you set yourself up for the authentic journey ahead.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'labyrinth.webp'}
                                                        alt="Labyrinth"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Reclaim Space</h4>
                                                    <p className={classes.paragraph}>
                                                        This stage is about gently challenging the fears and protective
                                                        mechanisms that have limited your relationships. Here, you'll
                                                        learn to embrace vulnerability, express your needs, and form
                                                        authentic connections. As you start engaging with others in ways
                                                        that honor your true self, you'll reclaim the social space that
                                                        aligns with your values.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'forward.webp'}
                                                        alt="Climbing"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Reconnect with Roots</h4>
                                                    <p className={classes.paragraph}>
                                                        In this stage, you'll work on resolving past issues, freeing
                                                        yourself from external pressures, and exploring how to integrate
                                                        your cultural heritage with your identity. By nurturing a
                                                        connection to your roots while staying true to who you are,
                                                        you'll find a renewed sense of belonging that supports your path
                                                        to authenticity.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'partnership.webp'}
                                                        alt="Partnership"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Transition to Authenticity</h4>
                                                    <p className={classes.paragraph}>
                                                        The final stage is about fully embracing your true self and
                                                        weaving your identity into every aspect of your life. You'll
                                                        rediscover your dreams and passions, overcome lingering fears,
                                                        and develop the confidence to express who you are. This stage
                                                        guides you toward independence, balancing support systems with
                                                        self-reliance, and using your unique experiences to positively
                                                        impact others.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </section>
                    {/* <section ref={eventsRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>Upcoming Events</h2>
                            <div className={classes.eventsWrapper}>Stay tuned!</div>
                            <div className={classes.eventsWrapper}>
                                <div className={classes.eventsBody}>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Bulb"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Navigate Career Transition with Ease
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 10
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-one"
                                                href="https://www.eventbrite.com/e/webinar-navigate-career-transition-with-ease-tickets-871233130747?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Winner"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Stepping into a Leadership Role with Certainty
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 17
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-two"
                                                href="https://www.eventbrite.com/e/webinar-stepping-into-a-leadership-role-with-certainty-tickets-871492446367?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Target"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Practical Guide to Mindfulness
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 24
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-three"
                                                href="https://www.eventbrite.com/e/webinar-practical-guide-to-mindfulness-tickets-871497591757?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.newsletterWrapper}>
                                <NewsletterForm id="main-events-newsletter-button" />
                            </div>
                        </div>
                    </section> */}
                    {/* <section ref={storiesRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div>
                                <h2 className={classes.title}>My Stories</h2>
                                <div className={classes.storiesContainer}>
                                    <ul className={classes.storiesList}>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-life-update"
                                                            href="https://medium.staskurg.com/summiting-new-heights-a-journey-of-personal-transformation-and-self-discovery-e0d8aeb3e29d"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Summiting New Heights: A Journey of Personal Transformation
                                                            and Self-Discovery <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        In 2023, I left my tech job to navigate my true calling,
                                                        embracing my immigrant background and LGBTQ+ identity as I
                                                        became a life coach.
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/lifeupdate.webp'}
                                                        alt="Life update story thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-doors"
                                                            href="https://medium.staskurg.com/passing-through-the-doors-3915e608afcd"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Passing Through the Doors <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        In pursuit of happiness and fulfillment, you discover a door
                                                        that can lead you there. The only condition is that you have to
                                                        let go of everything you know about yourself. Will you dare?
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/doors.webp'}
                                                        alt="Passing Through the Doors story thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-language"
                                                            href="https://medium.staskurg.com/finding-the-path-through-the-discovery-of-foreign-language-02910126d39c"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Finding the Path Through the Discovery of Foreign Language{' '}
                                                            <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        I was eighteen years old. I lived in Russia. I was on the bus,
                                                        riding home after my classes: I witnessed a conversation in
                                                        English for the first time.
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/language.webp'}
                                                        alt="Finding the Path Through the Discovery of Foreign Language thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div className={classes.callToActionContainer}>
                                <p className={classNames(classes.buttonWrapper, classes.onlyMobile)}>
                                    <button
                                        id="main-stories-button-book-session"
                                        onClick={executeScroll(sessionRef)}
                                        className={classNames(classes.button, classes.buttonPrimary)}
                                    >
                                        Create Action Plan
                                    </button>
                                </p>
                            </div>
                        </div>
                    </section> */}
                    <section ref={packagesRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div>
                                <h2 className={classes.title}>Packages & Rates</h2>
                                <div className={classes.descriptionWrapper}>
                                    <h3 className={classes.subtitle}>
                                        A simple and straightforward package that includes:
                                    </h3>
                                    <div className={classes.descriptionList}>
                                        <ul>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />6 or 12 coaching sessions (3
                                                or 6 months) designed to create tangible and lasting changes in your
                                                life.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />A personalized action plan
                                                based on your unique journey as an LGBTQ+ immigrant.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Two 55-minute coaching sessions per month focused on achieving
                                                breakthroughs that build your confidence and help you connect with your
                                                true self, available via phone or Zoom.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Two optional 15-minute support sessions each month to keep you motivated
                                                and address any immediate concerns.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Unlimited text and email support for ongoing guidance, encouragement,
                                                and navigating challenges along the way.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.packagesWrapper}>
                                    <div className={classes.packagesRate}>
                                        <div className={classes.slidingScaleContainer}>
                                            <h3 className={classes.subtitle}>
                                                <label htmlFor="rate-slider" className={classes.slidingScaleLabel}>
                                                    Select Your Rate:{' '}
                                                    {`$${rates[rateIndex].value}/session (${rates[rateIndex].name})`}
                                                </label>
                                            </h3>
                                            <input
                                                type="range"
                                                id="rate-slider"
                                                name="rate-slider"
                                                min="0"
                                                max={rates.length - 1}
                                                step="1"
                                                value={rateIndex}
                                                onChange={handleSliderChange}
                                                className={classes.slidingScaleSlider}
                                            />
                                        </div>
                                        <div className={classes.rateDescribtion}>
                                            <h3 className={classes.subtitle}>Why Pay More?</h3>
                                            <p className={classes.paragraph}>
                                                The sliding scale is designed with your financial situation in mind.
                                                We're all at different stages, and the value of money can vary for each
                                                of us. It's not just about your income; it's about choosing an
                                                investment that feels like a meaningful “stretch” without creating
                                                hardship. Investing at a level that challenges you can be a powerful
                                                motivator, encouraging you to take action and fully commit to the
                                                breakthroughs you're seeking.
                                            </p>
                                        </div>
                                        <div className={classes.rateDescribtion}>
                                            <h3 className={classes.subtitle}>
                                                To discover your authentic rate, ask yourself:
                                            </h3>
                                            <p className={classes.paragraph}>
                                                1. What level would feel fairly comfortable for me to pay?
                                            </p>
                                            <p className={classes.paragraph}>
                                                2. What level would be a “stretch” but still manageable, factoring in
                                                all available support?
                                            </p>
                                            <p className={classes.paragraph}>
                                                3. Am I willing to invest at a level that challenges me, knowing it will
                                                drive my commitment and growth?
                                            </p>
                                        </div>
                                        <div className={classes.rateDescribtion}>
                                            <p className={classes.paragraph}>
                                                <i>
                                                    * The "low bono" rate is available for those facing financial
                                                    difficulties. Please reach out to discuss availability.
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section ref={sessionRef}>
                        <div className={classes.divider}></div>
                        <div className={classes.sectionBookSession}>
                            <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                                <div>
                                    <h2 className={classes.title}>Ready to Explore Next Steps?</h2>
                                    <p className={classes.paragraph}>
                                        Book your free 55-minute "Create Action Plan" session to explore how coaching
                                        with me can support your unique journey. We'll work together to identify your
                                        challenges and craft a personalized plan that guides you confidently toward the
                                        life you've always envisioned. Take the first step to living your most authentic
                                        life.
                                    </p>
                                </div>
                            </div>
                            <div
                                className={classNames(classes.bookSessionBody, 'paperbell-calendar-container')}
                                data-package-id="100056"
                            ></div>
                        </div>
                        <div className={`${classes.sectionBio} ${classes.sectionPadding}`}>
                            <NewsletterForm id="main-discovery-newsletter-button" />
                        </div>
                    </section>
                </div>
                {/* <div className={classes.questionContainer}>
                    <button
                        id="main-button-questions"
                        className={classes.questionButton}
                        type="button"
                        onClick={handleOpenModal}
                    >
                        <span className={classes.questionButtonIcon}>
                            <QuestionIcon />
                        </span>
                        <span className={classes.questionText}>Any Questions?</span>
                    </button>
                </div> */}
                {/* {isModalOpen && <ModalSendEmail isOpen={isModalOpen} onClose={handleCloseModal} />} */}
            </main>
            <footer className={classes.footer}>
                <div className={classes.copyright}>
                    Stas Kurgansky - Professional Life Coach - {new Date().getFullYear()}
                </div>
                <div className={classes.footerSocialLinks}>
                    <a
                        id="footer-link-youtube"
                        href="https://www.youtube.com/@StasKurgCoach?sub_confirmation=1"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <YouTubeIcon /> YouTube
                    </a>
                    <a
                        id="footer-link-medium"
                        href="https://medium.staskurg.com/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <MediumIcon /> Medium
                    </a>
                    <a
                        id="footer-link-instagram"
                        href="https://www.instagram.com/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <InstagramIcon /> Instagram
                    </a>
                    <a
                        id="footer-link-linkedin"
                        href="https://www.linkedin.com/in/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <LinkedInIcon /> LinkedIn
                    </a>
                    <a
                        id="footer-link-amazon"
                        href="https://www.amazon.com/author/staskurgcoach"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <AmazonIcon /> Amazon
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Homepage;
